import React from 'react';

import { connect } from 'react-redux';
import { doArchiveStory } from '../../actions/archive';
import { InlineButton } from '../Buttons';

import './Story.css';

const Story = ({ story, columns, onArchive }) => {

    const {
        title,
        url,
        author,
        num_comments,
        points,
        objectID
    } = story;

    return (
        <div className="story">
            <span style={{ width: columns.title.width }}>
                <a href={url}>{title}</a>
            </span>
            <span style={{ width: columns.author.width }}>
                {author}
            </span>
            <span style={{ width: columns.comments.width }}>
                {num_comments}
            </span>
            <span style={{ width: columns.points.width }}>
                {points}
            </span>
            <span style={{ width: columns.archive.width }}>
                <InlineButton onClick={() => onArchive(objectID)}>
                    Archive
                </InlineButton>
            </span>
        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    onArchive: id => dispatch(doArchiveStory(id)),
});

export default connect(
    null,
    mapDispatchToProps
)(Story);